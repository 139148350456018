<template>
  <div class="home">
    <banners
      v-if="pageContent?.currentLocaleData?.banners || pageContent?.defaultLocaleData?.banners"
      :items="pageContent?.currentLocaleData?.banners || pageContent?.defaultLocaleData?.banners"
    />

    <div
      v-if="pageContent?.currentLocaleData?.categories || pageContent?.defaultLocaleData?.categories"
      class="card-category__container"
      :class="cardsModifier"
    >
      <card-category
        v-for="(item, itemIndex) in pageContent?.currentLocaleData?.categories ||
        pageContent?.defaultLocaleData?.categories"
        :key="itemIndex"
        :mod="itemIndex + 1"
        v-bind="item"
      />
    </div>

    <group-aero
      v-if="pageContent?.currentLocaleData?.aeroGroup?.display && aeroCategory"
      showAllBtn
      showArrows
      :category="aeroCategory"
      :currentLocaleContent="pageContent?.currentLocaleData?.aeroGroup"
      :defaultLocaleContent="pageContent?.defaultLocaleData?.aeroGroup"
    />

    <template v-for="collection in gameCollectionsList">
      <group-games v-if="collection" :key="collection.id" showAllBtn showArrows :category="collection" />
    </template>

    <group-providers showArrows show-all-btn />

    <group-promotions />

    <atomic-seo-text
      v-if="pageContent?.currentLocaleData?.pageMeta?.seoText"
      v-bind="pageContent.currentLocaleData.pageMeta.seoText"
    />
  </div>
</template>

<script setup lang="ts">
  import type { IHomePage } from '~/types';
  import type { ICollection } from '@skeleton/core/types';

  const { getContent } = useProjectMethods();

  const contentParams = {
    contentKey: 'homePageContent',
    contentRoute: ['pages', 'home'],
    isPage: true,
  };
  const { getContentData } = useContentLogic<IHomePage>(contentParams);
  const { data: pageContent } = await useLazyAsyncData(contentParams.contentKey, () => getContentData());

  const { getCollectionsList } = useGamesStore();
  const { data: gameCollections } = await useLazyAsyncData(() => getCollectionsList(), { server: false });
  const aeroCategory = computed(() =>
    gameCollections.value?.find(
      collection => collection.identity === pageContent.value?.currentLocaleData?.aeroGroup?.collectionIdentity
    )
  );

  const targetGameCollections = computed(() => {
    return (
      getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'gameCollections')?.map(
        (item: ICollection) => item.identity
      ) || []
    );
  });

  const gameCollectionsList = computed(() =>
    gameCollections.value
      ?.filter(collection => targetGameCollections.value.includes(collection.identity))
      ?.sort((a, b) => {
        return targetGameCollections.value?.indexOf(a.identity) - targetGameCollections.value?.indexOf(b.identity);
      })
  );

  const cardsModifier = computed(() => {
    const length =
      Object.keys(getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'categories'))
        ?.length || 0;
    return length ? `has-${length}-cards` : '';
  });

  onMounted(async () => {
    await getCollectionsList();
  });
</script>

<style src="@skeleton/assets/styles/pages/index.scss" lang="scss" />
